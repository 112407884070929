import React from 'react';

import Layout from './src/components/Layout';
import { LoadingFeedbackContextProvider } from './src/components/LoadingFeedback';
import './src/styles/brand.css';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => (
  <LoadingFeedbackContextProvider>{element}</LoadingFeedbackContextProvider>
);

export const wrapPageElement = Layout;
