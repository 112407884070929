import { ArrowPathIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';
import { createContext, Dispatch, FC, PropsWithChildren } from 'react';
import Helmet from 'react-helmet';

export const LoadingFeedbackContext = createContext<{
  loading: boolean;
  setLoading: Dispatch<boolean>;
}>({
  loading: false,
  setLoading: () => undefined,
});

export const LoadingFeedbackContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  // TODO change this with a set of actions and add / remove them to determine remaining operations in progress
  const [loading, setLoading] = useState(false);

  return (
    <LoadingFeedbackContext.Provider value={{ loading, setLoading }}>
      <Helmet
        bodyAttributes={
          {
            style: loading ? 'height: 100%; overflow: hidden;' : '',
          } as any
        }
      />
      {children}
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex flex-col items-center justify-center text-white">
          <ArrowPathIcon width="5em" height="5em" className="animate-spin" />
          <div className="mt-10 text-[24px]">Just a second...</div>
        </div>
      )}
    </LoadingFeedbackContext.Provider>
  );
};
