import { GatsbyBrowser } from 'gatsby';
import * as React from 'react';

import Footer from './Footer';
import Header from './Header';

export const Layout: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <>
      <Header />
      <main className="min-h-screen">{element}</main>
      <Footer />
    </>
  );
};

export default Layout;
