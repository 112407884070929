import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

const navigation = [
  { name: 'Home', href: 'https://www.forbec.ro/', current: false },
  {
    name: 'Despre Noi',
    href: 'https://www.forbec.ro/despre-noi/',
    current: false,
  },
  { name: 'Servicii', href: 'https://www.forbec.ro/servicii/', current: false },
  { name: 'Carieră', href: 'https://www.forbec.ro/cariera/', current: false },
  { name: 'FAQs', href: 'https://www.forbec.ro/cariera/', current: false },
  { name: 'Blog', href: 'https://www.forbec.ro/blog/', current: false },
  { name: 'Contact', href: 'https://www.forbec.ro/contact/', current: false },
];

const contact = [
  { icon: 'icon-phone', label: '0756 081 784', href: 'tel:+40 756 081 784' },
  {
    icon: 'icon-letter',
    label: 'sales@forbec.ro',
    href: 'mailto:sales@forbec.ro',
  },
  {
    icon: 'icon-location',
    label: 'str. Bistriței 3, Cluj-Napoca',
    href: 'https://goo.gl/maps/phEe4dsaVBcmcLkW9',
  },
];

export const social = [
  {
    icon: 'icon-facebook-logo1',
    href: 'https://www.facebook.com/forbec.services/',
  },
  {
    icon: 'icon-instagram-logo1',
    href: 'https://www.instagram.com/forbecservices/',
  },
  {
    icon: 'icon-linkedin2',
    href: 'https://www.linkedin.com/company/forbec-services',
  },
];

type HeaderProps = {};

const Header: FC<HeaderProps> = () => {
  return (
    <>
      <header className={clsx('shadow')}>
        <div className="hidden lg:block bg-[#f9f9fa] text-[18px]">
          <div className="container flex flex-row items-center space-x-4 max-w-7xl mx-auto p-2 lg:px-8">
            {contact.map((it) => (
              <div key={it.href} className="flex">
                <i className={clsx('icon text-[#4ca0e8] mr-2', it.icon)} />
                <a className="text-[#899198] text-[13px]" href={it.href}>
                  {it.label}
                </a>
              </div>
            ))}

            <div className="flex-1" />

            {social.map((it) => (
              <div key={it.href}>
                <a href={it.href}>
                  <i className={clsx('text-[#677d8f]', it.icon)} />
                </a>
              </div>
            ))}
          </div>
        </div>

        <Disclosure as="nav" className="bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-2 lg:px-8">
                <div className="relative flex h-[90px] items-center justify-between">
                  <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex flex-1 items-stretch justify-start">
                    <div className="flex flex-shrink-0 items-center">
                      <a href="https://www.forbec.ro/">
                        <img
                          className="w-[160px]"
                          src="/forbec_logo.svg"
                          alt="Forbec logo"
                        />
                      </a>
                    </div>

                    <div className="hidden lg:block flex-1" />

                    <div className="hidden lg:flex items-center space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={clsx(
                            'text-[#425d74] hover:text-[#4ca0e8]',
                            'uppercase text-[15px] font-bold'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="px-2 bg-[#f9f9fa]">
                  {contact.map((it) => (
                    <Disclosure.Button
                      key={it.href}
                      as="a"
                      href={it.href}
                      className={clsx(
                        'text-[#425d74] hover:text-[#4ca0e8]',
                        'block px-3 py-2 uppercase text-[13px] font-bold'
                      )}
                    >
                      <i
                        className={clsx('icon text-[#4ca0e8] mr-2', it.icon)}
                      />
                      <a className="text-[#899198] text-[13px]" href={it.href}>
                        {it.label}
                      </a>
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="space-y-1 px-2 pt-2 pb-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={clsx(
                        'text-[#425d74] hover:text-[#4ca0e8]',
                        'block px-3 py-2 uppercase text-[13px] font-bold'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </header>
    </>
  );
};

export default Header;
