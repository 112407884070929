import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

import { social } from './Header';

type FooterProps = {
  className?: string;
};

const contact = [
  {
    icon: 'icon-location',
    items: [
      {
        label: 'str. Bistriței 3, Cluj-Napoca',
        href: 'https://goo.gl/maps/phEe4dsaVBcmcLkW9',
      },
    ],
  },
  {
    icon: 'icon-phone',
    items: [
      { label: '0756 081 784', href: 'tel:+40 756 081 784' },
      { label: '0742 238 579', href: 'tel:0742238579' },
    ],
  },
  {
    icon: 'icon-letter',
    items: [
      { label: 'sales@forbec.ro', href: 'mailto:sales@forbec.ro' },
      { label: 'office@forbec.ro', href: 'mailto:office@forbec.ro' },
    ],
  },
  {
    icon: 'icon-clock1',
    items: [
      {
        label: 'L - V: 09:00 – 18:00',
        href: undefined,
      },
    ],
  },
];

const links = [
  {
    label: 'Curățenie de întreținere birouri',
    href: 'https://www.forbec.ro/servicii/curatenia-de-intretinere-birouri/',
  },
  {
    label: 'Curățenie generală',
    href: 'https://www.forbec.ro/servicii/curatenia-de-intretinere-birouri/',
  },
  {
    label: 'Curățenie după constructor',
    href: 'https://www.forbec.ro/servicii/curatenie-dupa-constructor/',
  },
  {
    label: 'Curățenie industrială',
    href: 'https://www.forbec.ro/servicii/curatenia-industriala/',
  },
  {
    label: 'Alpinism Utilitar',
    href: 'https://www.forbec.ro/servicii/alpinism-utilitar/',
  },
  {
    label: 'Spălare mecanizată pardoseli/mochetă',
    href: 'https://www.forbec.ro/servicii/curatare-pardoseli/',
  },
  {
    label: 'Întreținere spații verzi',
    href: 'https://www.forbec.ro/servicii/intretinere-spatii-verzi/',
  },
  {
    label: 'Curățare piatră naturală',
    href: 'https://www.forbec.ro/servicii/spalare-si-curatare-piatra-naturala/',
  },
  {
    label: 'Curățare pardoseli dure',
    href: 'https://www.forbec.ro/servicii/curatare-pardoseli-dure/',
  },
  {
    label: 'Curățare parcări',
    href: 'https://www.forbec.ro/servicii/curatare-parcari/',
  },
];

const legalLinks = [
  {
    label: 'Termeni și condiții',
    href: 'https://www.forbec.ro/termeni-si-conditii/',
  },
  {
    label: 'Politica de confidențialitate',
    href: 'https://www.forbec.ro/politica-de-confidentialitate/',
  },
];

const Footer: FC<FooterProps> = ({ className }) => {
  return (
    <footer className={clsx(className, 'pb-4 lg:pb-0')}>
      <div className="bg-[#f1f2f3] pt-[56px] pb-[30px] px-4">
        <div className="max-w-7xl mx-auto flex flex-col lg:flex-row space-y-[30px] lg:space-y-0">
          <div className="flex-1 flex flex-col space-y-4">
            <img
              className="w-[186px] -mt-[70px]"
              src="/footer_ribbon.webp"
              alt="best cleaning service award"
            />
            <img
              className="w-[200px]"
              src="/helvania_logo.webp"
              alt="helvania SA logo"
            />
            <div className="text-[16px] text-[#677d8f]">
              Owned by Helvania SA, Switzerland
            </div>
            <img
              className="w-[200px]"
              src="/verified.jpeg"
              alt="verified by risco.ro"
            />
            <img
              className="w-[290px]"
              src="/certifications.webp"
              alt="esq certifications"
            />
          </div>
          <div className="flex-1 flex flex-col space-y-2">
            <div className="text-[20px] font-bold text-[#4b5b68]">
              Date de contact
            </div>

            {contact.map((it) => (
              <div key={it.icon} className="flex items-center">
                <i className={clsx('icon text-[#4ca0e8] mr-3', it.icon)} />
                <div className="flex flex-col space-y-0.5">
                  {it.items.map((itt) => (
                    <a
                      key={`${it.icon}${itt.href}`}
                      className="text-[#899198] text-[16px]"
                      href={itt.href}
                    >
                      {itt.label}
                    </a>
                  ))}
                </div>
              </div>
            ))}

            {legalLinks.map((it) => (
              <div key={it.label} className="flex items-center">
                <i
                  className={clsx('icon text-[#4ca0e8] mr-3', 'icon-checkmark')}
                />
                <a
                  key={it.href}
                  className="text-[#899198] text-[16px]"
                  href={it.href}
                >
                  {it.label}
                </a>
              </div>
            ))}
          </div>

          <div className="flex-1 flex flex-col space-y-2">
            <div className="text-[20px] font-bold text-[#4b5b68]">
              Serviciile noastre
            </div>

            {links.map((it) => (
              <div key={it.label} className="flex items-center">
                <i
                  className={clsx('icon text-[#4ca0e8] mr-3', 'icon-checkmark')}
                />
                <a className="text-[#899198] text-[16px]" href={it.href}>
                  {it.label}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center lg:flex-row px-4 max-w-7xl mx-auto lg:justify-between">
        <div className={clsx('text-center text-[15px] text-[#677d8f] p-3')}>
          © 2023 Forbec Services. Toate drepturile rezervate.
        </div>
        <div className="flex space-x-4">
          {social.map((it) => (
            <div key={it.href}>
              <a href={it.href}>
                <i className={clsx('text-[#677d8f]', it.icon)} />
              </a>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
